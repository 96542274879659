import {
  type GetOpenShiftsDatesRequestQuery,
  getOpenShiftsDatesRequestQuery,
  type GetOpenShiftsDatesResponse,
  getOpenShiftsDatesResponse,
  type TimeSlot,
} from "@clipboard-health/contract-worker-app-bff";
import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { type DateRange } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  type ShiftPeriod,
  ShiftWindow,
  timeSlotToShiftPeriod,
} from "@src/appV2/Shifts/Shift/types";
import {
  type QueryClient,
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from "@tanstack/react-query";

export interface OpenShiftCountParams {
  startDate: Date;
  endDate: Date;
  filter: Omit<GetOpenShiftsDatesRequestQuery["filter"], "start">;
}

export interface OpenShiftDay {
  date: string;
  total: number;
  priorityAccess: string[];
  windows: Partial<Record<ShiftWindow, ShiftPeriod[]>>;
  am: number;
  pm: number;
  noc: number;
}

export type OpenShiftDatesResponse = Record<string, OpenShiftDay>;

const keyToWindow: Record<string, ShiftWindow> = {
  "A-TEAM": ShiftWindow.A_TEAM,
  CLIPBOARD_SCORE_FIRST: ShiftWindow.CLIPBOARD_SCORE_FIRST,
  CLIPBOARD_SCORE_SECOND: ShiftWindow.CLIPBOARD_SCORE_SECOND,
  FAMILIAR_WORKERS: ShiftWindow.FAMILIAR_WORKERS,
};

function mapOpenShiftDateWindows(
  windows: Record<string, TimeSlot[]>
): Partial<Record<ShiftWindow, ShiftPeriod[]>> {
  const result: Partial<Record<ShiftWindow, ShiftPeriod[]>> = {};

  Object.entries(windows).forEach(([key, value]) => {
    if (key in keyToWindow) {
      const mappedValue = value.map((timeSlot) => timeSlotToShiftPeriod[timeSlot]);

      if (mappedValue.length > 0) {
        result[keyToWindow[key]] = mappedValue;
      }
    }
  });

  return result;
}

export function toShiftDays(data: GetOpenShiftsDatesResponse): OpenShiftDatesResponse {
  return data.data.reduce<Record<string, OpenShiftDay>>((accumulator, openShift) => {
    accumulator[openShift.attributes.date] = {
      date: openShift.attributes.date,
      total: openShift.attributes.total,
      priorityAccess: openShift.attributes.priorityAccess,
      windows: mapOpenShiftDateWindows(openShift.attributes.windows),
      am: openShift.attributes.am ?? 0,
      pm: openShift.attributes.pm ?? 0,
      noc: openShift.attributes.noc ?? 0,
    };

    return accumulator;
  }, {});
}

export const GET_OPEN_SHIFT_COUNT_PATH = "/open-shift-dates";
export const GET_OPEN_SHIFT_COUNT_URL = `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}${GET_OPEN_SHIFT_COUNT_PATH}`;

export async function invalidateOpenShiftCount(queryClient: QueryClient): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [GET_OPEN_SHIFT_COUNT_URL] });
}

interface GetPaginatedOpenShiftCountParams
  extends Omit<OpenShiftCountParams, "startDate" | "endDate"> {
  initialDateRange: DateRange;
}

interface WrappedPaginatedOpenShiftDatesResponse {
  dateRange: DateRange;
  data: OpenShiftDatesResponse;
}

export function usePaginatedOpenShiftDates(
  params: GetPaginatedOpenShiftCountParams,
  options: UseInfiniteQueryOptions<WrappedPaginatedOpenShiftDatesResponse> = {}
) {
  const { initialDateRange, filter } = params;

  return useInfiniteQuery<WrappedPaginatedOpenShiftDatesResponse>({
    queryKey: [GET_OPEN_SHIFT_COUNT_URL, { filter }],
    queryFn: async ({ pageParam: nextDateRange }: QueryFunctionContext<QueryKey, DateRange>) => {
      const dateRange = nextDateRange ?? initialDateRange;

      const queryParams: GetOpenShiftsDatesRequestQuery = {
        filter: {
          start: {
            gte: dateRange.startDate.toISOString(),
            lte: dateRange.endDate.toISOString(),
          },
          timezone: filter.timezone,
          area: filter.area,
          qualifications: filter.qualifications,
        },
      };

      const response = await get({
        url: GET_OPEN_SHIFT_COUNT_URL,
        queryParams,
        requestSchema: getOpenShiftsDatesRequestQuery,
        responseSchema: getOpenShiftsDatesResponse,
      });

      return { data: toShiftDays(response.data), dateRange };
    },
    meta: {
      userErrorMessage: "Something went wrong while loading open shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_COUNT_FAILURE,
    },
    ...options,
  });
}
