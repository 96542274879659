import { Pill, type PillSize, type PillVariant } from "@clipboard-health/ui-components";

interface PriorityPlusShiftPillProps {
  size?: PillSize;
  variant?: PillVariant;
}

export function PriorityPlusShiftPill(props: PriorityPlusShiftPillProps) {
  const { size = "small", variant = "filled" } = props;

  return (
    <Pill
      size={size}
      variant={variant}
      label="Priority Plus"
      iconType="priority"
      backgroundColor={(theme) => theme.palette.intent?.success.background}
      color={(theme) => theme.palette.intent?.success.text}
      iconColor={(theme) => theme.palette.intent?.success.icon}
      sx={{
        width: "fit-content",
      }}
    />
  );
}
