import { type Window } from "@clipboard-health/contract-worker-app-bff";
import { isDefined } from "@clipboard-health/util-ts";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { isShiftPriorityValid } from "@src/appV2/lib/utils/isShiftPriorityValid";
import { USER_EVENTS } from "@src/constants";
import { type QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { differenceInMinutes, parseISO } from "date-fns";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

interface UseLogOpenShiftViewedProps {
  shiftId: string;
  offerId?: string;
  start: string;
  end: string;
  workplaceId: string;
  hourlyPay: number;
  qualification: string;
  shiftType: string;
  priorityTill?: string;
  isInstantReview?: boolean;
  missingDocuments: string[];
  window?: Window;
}

interface ShiftViewedLogCacheKeyProps {
  shiftId: string;
  offerId?: string;
}

function getShiftViewedLogCacheKey(props: ShiftViewedLogCacheKeyProps) {
  const { shiftId, offerId } = props;
  return ["shiftViewedLog", shiftId, offerId];
}

function cacheShiftViewedLog(queryClient: QueryClient, props: ShiftViewedLogCacheKeyProps) {
  queryClient.setQueryData(getShiftViewedLogCacheKey(props), () => true);
}

function getShiftViewedLogCache(queryClient: QueryClient, props: ShiftViewedLogCacheKeyProps) {
  const { shiftId, offerId } = props;

  if (!isDefined(shiftId) || !isDefined(offerId)) {
    return false;
  }

  return queryClient.getQueryData<boolean>(getShiftViewedLogCacheKey(props));
}

export function useLogOpenShiftViewed(props: UseLogOpenShiftViewedProps) {
  const queryClient = useQueryClient();

  const { shiftId, offerId } = props;

  const propsRef = useRef(props);

  const { mutate: logOpenShiftViewed } = useMutation({
    mutationFn: async (params: UseLogOpenShiftViewedProps) => {
      const {
        shiftId,
        offerId,
        start,
        end,
        workplaceId,
        hourlyPay,
        qualification,
        shiftType,
        priorityTill,
        isInstantReview,
        missingDocuments,
        window,
      } = params;

      if (!isDefined(shiftId) || !isDefined(offerId)) {
        // don't log until shift and offer are loaded
        return;
      }

      if (getShiftViewedLogCache(queryClient, { shiftId, offerId })) {
        // don't log if the shift has already been viewed
        return;
      }

      logEvent(USER_EVENTS.OPEN_SHIFT_VIEWED, {
        shift_id: shiftId,
        shiftOfferId: offerId,
        start,
        end,
        facility_id: workplaceId,
        hourly_pay: hourlyPay,
        agent_req: qualification,
        shift_type: shiftType,
        has_missing_documents: missingDocuments.length > 0,
        isPriorityAccessShift: priorityTill ? isShiftPriorityValid(priorityTill) : false,
        window,
        priorityAccessTimeRemaining: priorityTill
          ? differenceInMinutes(Date.now(), parseISO(priorityTill))
          : 0,
        is_instant_review: isInstantReview,
        missing_documents: missingDocuments,
      });
    },
    onSuccess: (_, params) => {
      cacheShiftViewedLog(queryClient, params);
    },
  });

  const { ref, inView } = useInView({
    rootMargin: "0px",
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      logOpenShiftViewed(propsRef.current);
    }
  }, [inView, shiftId, offerId, logOpenShiftViewed]);

  return { ref, inView };
}
