import {
  type UseGetMissingRequirements,
  useGetMissingRequirementsForWorkplaces,
} from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import { getDocumentCheckQualificationFromMultipleQualifications } from "@src/appV2/Accounts/Documents/helpers";
import { SpecialQualifications } from "@src/appV2/Qualifications/types";
import { useShiftDiscoveryUserFiltersContext } from "@src/appV2/redesign/ShiftDiscovery/Filters/useUserFiltersContext";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { getMissingAndPendingDocumentsForQualification } from "../Bookability/Documents/getMissingAndPendingDocumentsForQualification";
import { isShiftInstantReview } from "../Bookability/Documents/InstantReview/isShiftInstantReview";
import { isDocumentInstantReview } from "../Bookability/Documents/isDocumentInstantReview";
import type { OpenShift, OpenShiftWorkplace } from "./types";

interface OpenShiftsMissingRequirementsProps {
  openShifts: OpenShift[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
}

function getShiftsMissingRequirementsMap(
  openShifts: OpenShift[],
  workerQualifications: string[],
  missingRequirementsMap: Map<string, UseGetMissingRequirements>
) {
  return new Map(
    openShifts.map((shift) => {
      const qualificationForDocumentCheck = getDocumentCheckQualificationFromMultipleQualifications(
        {
          shiftQualificationRequirement: shift.attributes.qualification,
          selectedWorkerQualifications: workerQualifications,
        }
      );

      const { missingDocuments, pendingDocuments } = getMissingAndPendingDocumentsForQualification(
        shift.relationships.workplace.data.id,
        qualificationForDocumentCheck ?? shift.attributes.qualification,
        missingRequirementsMap
      );

      const visibleMissingDocuments = missingDocuments.filter((document) => document.visibleToHCP);

      // Don't show missing documents count if all missing documents are instant review
      const areAllMissingDocumentsInstantReview = visibleMissingDocuments.every((document) =>
        isDocumentInstantReview({
          document,
        })
      );
      const showMissingDocumentsCount =
        visibleMissingDocuments.length > 0 && !areAllMissingDocumentsInstantReview;

      return [
        shift.id,
        {
          missingDocuments: visibleMissingDocuments,
          pendingDocuments,
          showMissingDocumentsCount,
          isInstantReview: isShiftInstantReview({
            missingDocuments,
          }),
        },
      ];
    })
  );
}

/**
 * Aggregates the missing requirements for given open shifts,
 * including missing and pending documents, whether they are
 * instant review or not, and if we should display the missing
 * documents count in the UI (used by the shift card).
 *
 * @returns map of shift id to missing requirements
 */
export function useOpenShiftsMissingRequirements(props: OpenShiftsMissingRequirementsProps) {
  const { openShifts, workplacesMap } = props;

  const { userId: workerId } = useDefinedWorker();

  const { licenses: selectedLicensesFilter, availableLicenses } =
    useShiftDiscoveryUserFiltersContext();

  const selectedWorkerQualifications =
    selectedLicensesFilter.length > 0 ? selectedLicensesFilter : availableLicenses;

  // CAREGIVER shifts are shown to workers with the CNA qualification.
  const workerQualificationsForDocumentCheck = selectedWorkerQualifications.includes(
    SpecialQualifications.CNA
  )
    ? [...selectedWorkerQualifications, SpecialQualifications.CAREGIVER]
    : selectedWorkerQualifications;

  const missingRequirements = useGetMissingRequirementsForWorkplaces(
    workerId,
    Array.from(workplacesMap.values(), (workplace) => ({
      id: workplace.id,
      type: workplace.attributes.type,
      msa: workplace.attributes.location.msa,
      state: workplace.attributes.location.state,
      region: workplace.attributes.location.region,
    })),
    workerQualificationsForDocumentCheck,
    {
      enabled: openShifts.length > 0,
    }
  );

  return getShiftsMissingRequirementsMap(
    openShifts,
    workerQualificationsForDocumentCheck,
    missingRequirements.results
  );
}
