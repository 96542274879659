import constate from "constate";
import { compareAsc, parseISO } from "date-fns";
import { useRef } from "react";
import type { VirtuosoHandle } from "react-virtuoso";

import { type OpenShiftListItem, type OpenShiftWorkplace, type TimeSlot } from "../types";
import type { VirtualShiftListItem } from "./types";
import { getVirtualShiftListGroupsMap } from "./utils";

interface UseVirtualShiftListProps {
  shifts: OpenShiftListItem[];
  timeSlotFilters?: TimeSlot[];
  workplacesMap: Map<string, OpenShiftWorkplace>;
}

export function useVirtualShiftList(props: UseVirtualShiftListProps) {
  const { timeSlotFilters = [], shifts, workplacesMap } = props;

  const shiftListGroupByDate = getVirtualShiftListGroupsMap({
    shifts,
    workplacesMap,
    visibleTimeSlots: timeSlotFilters,
  });

  const virtualShiftListItems: VirtualShiftListItem[] = [...shiftListGroupByDate.entries()]
    .sort((a, b) => {
      const dateA = parseISO(a[0]);
      const dateB = parseISO(b[0]);
      return compareAsc(dateA, dateB);
    })
    // Avoid dispalying empty groups
    .filter((group) => group[1].listItems.some((item) => item.type !== "group-header"))
    .flatMap((group) => group[1].listItems);

  const visibleOpenShiftsCount = virtualShiftListItems.filter(
    (item) => item.type === "open-shift"
  ).length;

  const listRef = useRef<VirtuosoHandle>(null);

  return {
    listRef,
    virtualShiftListItems,
    visibleOpenShiftsCount,
  };
}

export const [VirtualShiftListContextProvider, useVirtualShiftListContext] =
  constate(useVirtualShiftList);
