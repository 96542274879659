import { type OpenShiftListItem, type TimeSlot } from "../types";
import type { VirtualShiftListGroup, VirtualShiftListGroupHeader } from "./types";

interface BuildVirtualShiftListGroupProps {
  dateIso: string;
  shiftsForDate: OpenShiftListItem[];
  visibleTimeSlots: TimeSlot[];
}

export function buildVirtualShiftListGroup(
  props: BuildVirtualShiftListGroupProps
): VirtualShiftListGroup {
  const { dateIso, shiftsForDate, visibleTimeSlots } = props;

  const groupHeader: VirtualShiftListGroupHeader = {
    type: "group-header",
    dateIso,
    totalShiftsCount: shiftsForDate.length,
    visibleTimeSlots,
  };

  const group: VirtualShiftListGroup = {
    dateIso,
    shifts: shiftsForDate,
    listItems: [groupHeader],
  };

  shiftsForDate.forEach((shift) => {
    const { timeSlot } = shift.attributes;

    if (visibleTimeSlots.length > 0 && !visibleTimeSlots.includes(timeSlot)) {
      return;
    }

    group.listItems.push(shift);
  });

  return group;
}
