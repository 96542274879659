import { Stack, type StackProps } from "@mui/material";

interface ShiftCardPillsWrapperProps extends StackProps {
  children: React.ReactNode;
}

export function ShiftCardPillsWrapper(props: ShiftCardPillsWrapperProps) {
  const { children, ...restProps } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
      {...restProps}
    >
      {children}
    </Stack>
  );
}
