import { Pill, type PillSize, type PillVariant } from "@clipboard-health/ui-components";

interface PriorityShiftPillProps {
  size?: PillSize;
  variant?: PillVariant;
}

export function PriorityShiftPill(props: PriorityShiftPillProps) {
  const { size = "small", variant = "filled" } = props;

  return (
    <Pill
      variant={variant}
      size={size}
      label="Priority"
      iconType="priority"
      backgroundColor={(theme) => theme.palette.shift?.priority.background}
      color={(theme) => theme.palette.shift?.priority.text}
      iconColor={(theme) => theme.palette.shift?.priority.icon}
      sx={{
        width: "fit-content",
      }}
    />
  );
}
