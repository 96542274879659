import { type BasePillProps, Pill } from "@clipboard-health/ui-components";
import { PillSkeleton } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";

type ShiftQualificationPillProps = Omit<BasePillProps, "iconType" | "label"> & {
  qualificationText: string;
  isLoading?: boolean;
};

export function ShiftQualificationPill(props: ShiftQualificationPillProps) {
  const {
    qualificationText,
    variant = "outlined",
    size = "small",
    sx,
    isLoading,
    ...pillProps
  } = props;

  if (isLoading) {
    return <PillSkeleton width="2rem" />;
  }

  return (
    <Pill
      size={size}
      label={qualificationText}
      variant={variant}
      sx={mergeSxProps({ width: "fit-content" }, sx)}
      backgroundColor={(theme) => theme.palette.background.tertiary}
      {...pillProps}
    />
  );
}
