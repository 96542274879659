import { ShiftType } from "../constants";
import { type Window } from "../Open/types";
import { resolveShiftType } from "../resolveShiftType";

interface IsPriorityShiftProps {
  isUrgent?: boolean;
  priorityTill?: string;
  window?: Window;
}

export function isPriorityShift(props: IsPriorityShiftProps) {
  const { isUrgent = false, priorityTill, window } = props;

  const shiftType = resolveShiftType({
    isUrgent,
    priorityTill,
    window,
  });

  return (
    shiftType === ShiftType.PRIORITY ||
    shiftType === ShiftType.PRIORITY_PLUS ||
    shiftType === ShiftType.PRIORITY_LEGACY
  );
}
