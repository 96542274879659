import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { facilitySchema } from "@src/appV2/Facilities/types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

const FACILITY_OPEN_SHIFT_COUNT_ENDPOINT_URL = `${environmentConfig.REACT_APP_BFF_URL}/calendar/facilityOpenShiftCount`;

const workplaceWithShiftCountQuerySchema = z.object({
  range: z.object({
    start: z.string(),
    end: z.string(),
  }),
  coordinates: z.array(z.number()),
  distance: z.number(),
  qualification: z.string(),
  specialities: z
    .object({
      experience: z.array(z.string()).optional(),
      hasSedationExperience: z.boolean().optional(),
      hasTrayAssemblyExperience: z.boolean().optional(),
    })
    .optional(),
  isPlain: z.boolean().optional(),
  facilityId: z.string().optional(),
  isAgent: z.literal(true),
});

export type WorkplaceWithShiftCountParams = z.infer<typeof workplaceWithShiftCountQuerySchema>;

const workplaceWithShiftCountResponseSchema = z.array(
  facilitySchema.extend({
    shiftsCount: z.number(),
  })
);

export type WorkplaceWithShiftCountResponse = z.infer<typeof workplaceWithShiftCountResponseSchema>;

export function useWorkplaceWithShiftCount(
  params: WorkplaceWithShiftCountParams,
  options: UseGetQueryOptions<WorkplaceWithShiftCountResponse>
): UseQueryResult<WorkplaceWithShiftCountResponse> {
  return useGetQuery<WorkplaceWithShiftCountParams, WorkplaceWithShiftCountResponse>({
    url: FACILITY_OPEN_SHIFT_COUNT_ENDPOINT_URL,
    requestSchema: workplaceWithShiftCountQuerySchema,
    responseSchema: workplaceWithShiftCountResponseSchema,
    queryParams: params,
    meta: {
      userErrorMessage: "Something went wrong while loading workplace shift count",
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_OPEN_SHIFT_COUNT_FAILURE,
    },
    ...options,
  });
}
